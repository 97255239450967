export const CartIcon = (props) => {
  return (
    <svg
      id="uuid-87b90d53-df89-42c3-9aa1-f0d02b9cdf35"
      xmlns="http://www.w3.org/2000/svg"
      width={20.06}
      height={20.04}
      {...props}
    >
      <defs>
        <style>{".uuid-5fb953da-65b4-4458-840e-fb8df8a1cbcb{fill:#fff}"}</style>
      </defs>
      <g id="uuid-c9dbbaf8-73e3-4a07-9383-028860227ff6">
        <path
          id="uuid-6c73cfb0-caa4-4b14-bc11-1e719d84b7f8"
          className="uuid-5fb953da-65b4-4458-840e-fb8df8a1cbcb"
          d="M3.42 6.01a.43.43 0 0 0-.4.4L1.08 18.76v.13c0 .07.07.07.13.07h17.63c.07 0 .13 0 .13-.07v-.13L17.3 6.34a.426.426 0 0 0-.4-.33H3.42Zm15.42 14.02H1.22c-.36.01-.7-.14-.94-.4-.23-.28-.32-.64-.27-1L1.95 6.21c.13-.69.7-1.21 1.4-1.27h13.49c.73 0 1.34.54 1.4 1.27l1.8 12.42c.06.36-.04.72-.27 1-.23.27-.57.42-.93.4Z"
        />
        <path
          id="uuid-12b940fb-0c37-4d0f-82b4-e7b1241539c6"
          className="uuid-5fb953da-65b4-4458-840e-fb8df8a1cbcb"
          d="M13.3 9.75a.57.57 0 0 1-.53-.53V3.74a2.693 2.693 0 0 0-2.72-2.67h-.02c-1.49 0-2.7 1.18-2.74 2.67v5.47c0 .29-.24.53-.53.53-.29 0-.53-.24-.53-.53V3.74C6.26 1.64 7.99-.03 10.09 0a3.8 3.8 0 0 1 3.74 3.74v5.47c0 .29-.23.53-.52.53h-.02Z"
        />
      </g>
    </svg>
  );
};

export const BodyLogo = (props) => {
  return (
    <svg
      id="body-logo"
      xmlns="http://www.w3.org/2000/svg"
      width={60.45}
      height={37.96}
      {...props}
    >
      <defs>
        <style>{".uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450{fill:#fff}"}</style>
      </defs>
      <path
        id="uuid-68d662c9-9be1-4ab2-b1e2-6f4f62db2b42"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M14.87 7.99h4.46v25.99h5.95V8.01h4.6v.57c0 8.96-.01 17.92.02 26.88-.02.69-.29 1.35-.77 1.85-.4.41-.95.63-1.52.61-3.38-.01-6.77-.04-10.15.02-.76.05-1.5-.24-2.02-.8-.45-.47-.67-1.12-.6-1.78.02-.16.02-.32.02-.48V7.98"
      />
      <path
        id="uuid-249dc955-7335-41b2-b9cd-9cc760304d9a"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M33.26 37.87V8h12.28v3.93h-7.71v8.69h6.57v3.98h-6.57v9.31h7.81v3.95H33.27Z"
      />
      <path
        id="uuid-99403ff2-940e-47f3-8698-847b35b63771"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M12.18 8.01v3.92H4.55v8.87h6.49v3.91H4.53v13.26H0V8.01h12.18Z"
      />
      <path
        id="uuid-234f24a9-3269-45e2-85a5-78c37a2a0413"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M60.45 37.89H48.69V8.01h4.53v25.92h7.23v3.97Z"
      />
      <path
        id="uuid-7ed097d8-415b-4dd5-aab0-d5530a360740"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M24.7 2.86A2.825 2.825 0 0 1 27.49 0h.07c1.58 0 2.86 1.28 2.85 2.86 0 1.58-1.28 2.86-2.86 2.85-1.58 0-2.85-1.28-2.85-2.85"
      />
      <path
        id="uuid-2c697b1c-e81a-43e4-b3e6-f7e39340f0c5"
        className="uuid-ebc0bdad-c5ca-472a-a4fa-e809b10f9450"
        d="M14.36 2.85A2.796 2.796 0 0 1 17.11 0h.06c1.56.04 2.8 1.31 2.81 2.87a2.838 2.838 0 0 1-2.84 2.81 2.745 2.745 0 0 1-2.79-2.69v-.15"
      />
    </svg>
  );
};

export const StoreIcon = (props) => {
  return (
    <svg
      id="store-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={61.08}
      height={49.63}
      viewBox="0 0 61.08 49.63"
      {...props}
    >
      <defs>
        <style>
          {
            ".uuid-291e3c4f-fee9-4df9-87dc-f6314b53843c,.uuid-955d0ee1-e079-48b2-9aa6-313f58f763ef{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}.uuid-291e3c4f-fee9-4df9-87dc-f6314b53843c{fill:#242424}"
          }
        </style>
      </defs>
      <path
        className="uuid-955d0ee1-e079-48b2-9aa6-313f58f763ef"
        d="M4.62 14.04v32.77c0 1.14.93 2.07 2.07 2.07H40.9c2.12 0 3.85-1.72 3.85-3.85V13.88"
      />
      <path
        className="uuid-955d0ee1-e079-48b2-9aa6-313f58f763ef"
        d="M48.62 16.51 24.55.75.75 16.51M36.99 48.88V27.74H12.38v21.14M17.46 15.44h14.45M17.46 20.79h14.45"
      />
      <path
        className="uuid-291e3c4f-fee9-4df9-87dc-f6314b53843c"
        d="M58.19 48.88H42.07c-1.24 0-2.23-1.06-2.13-2.3l1.11-14.86a2.131 2.131 0 0 1 2.13-1.98h13.75c1.11 0 2.04.85 2.13 1.96l1.25 14.86c.1 1.25-.88 2.32-2.13 2.32Z"
      />
      <path
        className="uuid-291e3c4f-fee9-4df9-87dc-f6314b53843c"
        d="M54.98 33.32c0 2.67-2.16 4.83-4.83 4.83s-4.83-2.16-4.83-4.83"
      />
      <path
        className="uuid-955d0ee1-e079-48b2-9aa6-313f58f763ef"
        d="M45.32 29.74v-1.97c0-2.67 2.16-4.83 4.83-4.83s4.83 2.16 4.83 4.83v1.97"
      />
    </svg>
  );
};

export const OnlineIcon = (props) => {
  return (
    <svg
      id="online-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={70.14}
      height={45.76}
      viewBox="0 0 70.14 45.76"
      {...props}
    >
      <defs>
        <style>
          {
            ".uuid-4af78b74-e870-4b93-8385-665bb795f1fe{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}"
          }
        </style>
      </defs>
      <rect
        className="uuid-4af78b74-e870-4b93-8385-665bb795f1fe"
        x={5.88}
        y={0.75}
        width={58.37}
        height={39.12}
        rx={3.99}
        ry={3.99}
      />
      <path
        className="uuid-4af78b74-e870-4b93-8385-665bb795f1fe"
        transform="rotate(90 35.065 20.315)"
        d="M18.71-5.66h32.71v51.95H18.71z"
      />
      <rect
        className="uuid-4af78b74-e870-4b93-8385-665bb795f1fe"
        x={0.75}
        y={39.87}
        width={67.99}
        height={5.13}
        rx={1.66}
        ry={1.66}
      />
      <path
        d="m50.61 13.1 5.12 14.29c.16.45.74.59 1.09.24l2.85-2.85 6.28 6.28c.26.26.67.26.93 0l2.32-2.32c.26-.26.26-.67 0-.93l-6.3-6.3 3.27-3.27c.35-.35.21-.93-.25-1.09l-14.47-4.9a.659.659 0 0 0-.83.85Z"
        style={{
          fill: "#242424",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.5px",
        }}
      />
      <path
        className="uuid-4af78b74-e870-4b93-8385-665bb795f1fe"
        d="M26.15 42.38h18"
      />
    </svg>
  );
};

export const FuelLogoIcon = (props) => {
  return (
    <svg
      width={12}
      height={12}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.22 17.24"
      {...props}
    >
      <path
        data-name="Path 27"
        d="M18.73 1.58a5.22 5.22 0 0 0-7.38-.1v.05L10.13 2.7 8.92 1.53a5.21 5.21 0 0 0-7.38 0h-.05a5.6 5.6 0 0 0 .2 7.81l.88.87 6.57 6.6a1.36 1.36 0 0 0 1.94 0l6.58-6.58.87-.87a5.6 5.6 0 0 0 .2-7.81"
      />
    </svg>
  );
};
