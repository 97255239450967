import { useRef, useState } from "react";
import getProduct from "../../util/getProduct";
import Home from "./Home.component";
import "./Home.style.scss";

const HomeContainer = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [product, setProduct] = useState();
  const timeoutRef = useRef();
  const canvasRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const updateValue = (e) => {
    setValue(e.target.value);
  };
  const createQrCode = async () => {
    setLoading(true);
    const productSku = await getProduct({ sku: value });
    const { response } = productSku ?? "";
    if (response === null) {
      timeoutRef.current = "";
      setStatus("Something Went Wrong");
      timeoutRef.current = setTimeout(() => {
        setStatus("");
      }, 5000);
      setLoading(false);
      return;
    }

    if (response === "No Product Found") {
      timeoutRef.current = "";
      setStatus("No Product Found");
      timeoutRef.current = setTimeout(() => {
        setStatus("");
      }, 5000);
      setLoading(false);
      return;
    }

    if (!productSku) {
      timeoutRef.current = "";
      setStatus("Invalid Input");
      timeoutRef.current = setTimeout(() => {
        setStatus("");
      }, 5000);
      setLoading(false);
      return;
    }

    setProduct(response);
    setLoading(false);
  };

  const downloadQrCode = () => {
    const element = canvasRef.current.canvas.current;
    const canvas = document.getElementById(element.id);
    const a = document.createElement("a");
    a.href = canvas.toDataURL("image/png");
    a.download = `${value}_qr.png`;
    a.click();
  };

  const containerFunctions = {
    setValue,
    createQrCode,
    updateValue,
    downloadQrCode,
  };
  const state = { value, status, loading, product, canvasRef, BASE_URL };
  return <Home {...containerFunctions} {...state} />;
};

export default HomeContainer;
