import {
  BodyLogo,
  CartIcon,
  OnlineIcon,
  StoreIcon,
} from "../../components/CustomIcons/CustomIcons";
import Footer from "../../components/Footer/Footer";

import { IoIosClose } from "react-icons/io";
import ImageGallery from "react-image-gallery";
import { Outlet } from "react-router-dom";
import Error from "../Error";
const Product = ({
  response,
  stripHtmlTags,
  toggleAvailabilityBar,
  toggleOverylay,
  activeProduct,
  prepareImageGalleryPhotos,
  handleClick,
}) => {
  if (
    !response ||
    response === "No Product Found" ||
    response === "Not Salable"
  ) {
    return <Error customError={response} />;
  }

  const {
    base: {
      base_image,
      brand,
      color,
      description,
      discount_percentage,
      extra_images,
      finalPrice,
      link,
      name,
      regularPrice,
      stock,
    },
  } = response ?? "";

  return (
    <>
      <Outlet context={[response]} />
      <div className="thumb">
        <ImageGallery
          items={prepareImageGalleryPhotos()}
          loading={"eager"}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>

      <div className="sticky-bar">
        <div className="wrapper">
          <div className="col left">
            <BodyLogo />
          </div>
          <div className="col right">
            <a href={link} target="_blank">
              <div className="actions-wrapper">
                <span>ΚΑΝΤΟ ΔΙΚΟ ΣΟΥ</span>
                <span className="price-box">
                  <div className="special-price">{finalPrice}€</div>
                  {discount_percentage ? (
                    <div className="old-price">{regularPrice}€</div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="cart-icon">
                <CartIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="information">
        <h1>{stripHtmlTags(name)}</h1>

        <p>{stripHtmlTags(description)}</p>

        <div className="sku">
          <span className="label">SKU</span>
          <span className="value">{response.additional[0].sku}</span>
        </div>

        <div className="variations">
          <span className="label">ΔΙΑΘΕΣΙΜOΤΗΤΑ</span>
          <small>
            Επίλεξε μέγεθος για να δεις live διαθεσιμότητα σε e-shop και στο κατάστημα της Αθήνας.
          </small>
          <ul id="variation">
            {response?.additional?.length > 0 ? (
              <>
                {response?.additional.map((add, idx) => {
                  return (
                    <li
                      key={idx}
                      className="variation"
                      onClick={() => toggleAvailabilityBar(add)}
                    >
                      <span
                        className={
                          activeProduct.sku === add.sku ? "active" : ""
                        }
                      >
                        {add.size}
                      </span>
                    </li>
                  );
                })}
              </>
            ) : (
              "NO"
            )}
          </ul>
        </div>
        <div className="stock-overlay" onClick={toggleOverylay}></div>
        <div id="stock">
          {activeProduct ? (
            <button
              type="button"
              id="close-stock-btn"
              onClick={toggleOverylay}
              className="close-stock-btn"
            >
              <IoIosClose color="white" className="close-stock-btn" />
            </button>
          ) : (
            ""
          )}

          <div className="stock-wrapper">
            <div
              className="stock-design"
              onClick={() => handleClick(activeProduct)}
              style={{ cursor: "pointer" }}
            >
              <OnlineIcon />
              <strong>
                Ε-SHOP
                <span>
                  {activeProduct?.["stock"]?.["flag"]
                    ? activeProduct["stock"]["text"]
                    : "N/A"}
                </span>
              </strong>
            </div>

            <div className="stock-design">
              <StoreIcon />
              <strong>
                ΚΑΤΑΣΤΗΜΑ
                <span>{activeProduct["athens-availability"]}</span>
              </strong>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
