import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./Pages/Error";
import Home from "./Pages/Home";
import Product from "./Pages/Product/";
import getProduct from "./util/getProduct";

const router = createBrowserRouter([
  {
    path: "/generate",
    element: <Home />,
    errorElement: <div>ERROR</div>,
  },
  {
    path: "/:sku",
    loader: async ({ params }) => {
      const { response, error } = await getProduct(params);

      if (response === null) {
        return null;
      }
      if (response === "No Product Found") {
        return response;
      }
      if (response === "Not Salable") {
        return response;
      }

      if (!response) {
        return null;
      }

      return response;
    },
    element: <Product />,
    errorElement: <Error />,
  },
  {
    errorElement: <h1>ERROR 404 - NOT FOUND</h1>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
