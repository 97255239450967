import { useRouteError } from "react-router-dom";

import Error from "./Error.component";
import "./Error.style.scss";

const ErrorContainer = ({ customError }) => {
  const error = useRouteError();

  return <Error error={error} customError={customError} />;
};

export default ErrorContainer;
