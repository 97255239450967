import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const getProduct = async ({ sku }) => {


  if (sku) {
    try {
      let res = await axios({
        method: "GET",
        url: API_URL,
       params: { sku } ,
      });

      if (!res || res.status !== 200) {
        return { response: null, error: "No Response" };
      }

      if (res.data.length < 1) {
        return { response: "No Product Found" };
      }
      if (res.data === "Not Salable") {
        return { response: "Not Salable" };
      }
      if (res?.data) {
        if (typeof res.data === String) return { response: res.data };
        const prepdata = JSON.parse(res.data);
        return { response: prepdata };
      }

      return { response: null };
    } catch (error) {
      return { response: null, error: error };
    }
  } else {
    return null;
  }
};

export default getProduct;
