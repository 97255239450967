import { QRCode } from "react-qrcode-logo";
import { Outlet } from "react-router-dom";

const Home = ({
  value,
  updateValue,
  createQrCode,
  status,
  loading,
  product,
  canvasRef,
  downloadQrCode,
  BASE_URL,
}) => {
  return (
    <>
      <div className="container">
        <div className="container-items">
          <h1 className="container-title">Welcome to Fuel QR Generator</h1>

          <div className="container-image">
            {product && !loading ? (
              <>
                <QRCode
                  value={`${BASE_URL}/${value}`}
                  bgColor={"white"}
                  fgColor={"black"}
                  // qrStyle={"dots"}
                  // eyeRadius={10}
                  // eyeColor={"yellow"}
                  // logoOpacity={"0.8"}
                  logoWidth={"500"}
                  logoHeight={"500"}
                  // removeQrCodeBehindLogo
                  quietZone={"50"}
                  logoImage={"/fuel-logo.jpg"}
                  size={2000}
                  ref={canvasRef}
                />

                <h2>
                  <a
                    href={`/${value}`}
                    target="_blank"
                  >{`${BASE_URL}/${value}`}</a>
                </h2>
              </>
            ) : (
              <img className="logo" alt="fuel-logo" src="/fuel-logo.jpg" />
            )}
          </div>
          {loading ? (
            "Loading"
          ) : (
            <form
              className="generate"
              onSubmit={(e) => {
                e.preventDefault();
                createQrCode();
              }}
              autoComplete="off"
            >
              <h3 className="container-status">{status}</h3>
              <input
                onChange={updateValue}
                className="generate-input"
                name="sku"
                required
                type={"text"}
                value={value}
                autoFocus
              />
              <button
                className="generate-btn"
                type="button"
                onClick={createQrCode}
              >
                GENERATE
              </button>
            </form>
          )}
        </div>
        {product && !loading ? (
          <button
            className="download-qr"
            type="button"
            onClick={downloadQrCode}
          >
            Download QR
          </button>
        ) : (
          ""
        )}
      </div>
      <Outlet />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
