import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import Product from "./Product.component";
import "./Product.style.scss";
const ProductContainer = () => {
  const response = useLoaderData();
  const [activeProduct, setActiveProduct] = useState("");

  const stripHtmlTags = (string) => {
    return string?.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const toggleAvailabilityBar = (add) => {
    document.querySelector(".variation").classList.toggle("active");
    document.querySelector("body").classList.add("animateme");
    setActiveProduct(add);
  };

  const toggleOverylay = (e) => {
    if (
      e.target.classList.contains("stock-overlay") ||
      e.target.classList.contains("close-stock-btn")
    ) {
      document.querySelector("body").classList.remove("animateme");
      setActiveProduct("");
    }
  };

  const prepareImageGalleryPhotos = () => {
    let allPhotos = [
      {
        original: response.base.base_image,
        thumbnail: response.base.base_image,
      },
    ];
    const additionalPhotos = response.base.extra_images;
    if (additionalPhotos)
      additionalPhotos.forEach((extra) => {
        const obj = {};
        obj["original"] = extra;
        obj["thumbnail"] = extra;
        allPhotos.push(obj);
      });
    return allPhotos;
  };

  const handleClick = () => {
    if (response) {
      window.location.href = response.base.link;
    }
  };

  const containerFunctions = {
    stripHtmlTags,
    toggleAvailabilityBar,
    toggleOverylay,
    prepareImageGalleryPhotos,
    handleClick,
  };
  const state = { response, activeProduct };

  return <Product {...state} {...containerFunctions} />;
};

export default ProductContainer;
